
export const getCssStatus = (v) => {
    let css = {
        REJEC: 'reject_status_point',
        APPRO: 'approved_status_point',
        PENDI: 'pending_status_point'
    }
    return css[v]
}

export const getCssStatusAssetExchange = (v) => {
    let css = {
        PUBLI: 'publish_status',
        DRAFT: 'draft_status',
        SOLDO: 'soldout_status'
    }
    return css[v]
}
