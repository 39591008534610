<template>
    <main>              
        <UserHeader />
        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Mis Puntos</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Mis Puntos</h2> 
                
                <total-point-exchange>
                    <template v-slot:up>
                        {{ dataTotalPointExchange.label }}
                    </template>
                    <template v-slot:down>
                        {{ dataTotalPointExchange.value }}
                    </template>
                    
                </total-point-exchange>
                
                <div class="page-content">
                    
                    <div class="tabbed">
                        <input type="radio" id="tab1" name="css-tabs" checked>
                        <input type="radio" id="tab2" name="css-tabs">
                        <ul class="tabs">
                            <li class="tab" @click="changeTabHistoryPoint"><label for="tab1">Historial de puntos</label></li>
                            <li class="tab" @click="changeTabMyExchange"><label for="tab2">Mis canjes</label></li>
                        </ul>	
                        <div class="tab-content">	                            
                                
                            <p class="form__success">
                                IMPORTANTE. Por cada compra de productos Barrington, exija a su tienda solicite la aprobación 
                                de sus puntos. Tan pronto la tienda registre su compra, los puntos apareceran en su historial 
                                como PENDIENTES de aprobación por BARRINGTON.
                            </p>
                            <p>
                                Revisa tus puntos acumulados así como los canjeados.
                            </p>
                            <div class="table-normal">
                                <table class="table-normal__content">
                                    <tr>                                        
                                        <th>Código</th>
                                        <th>Lugar / Motivo</th>
                                        <th>Registro</th>
                                        <th>Puntos</th>
                                        <th>Estado</th>                                        
                                    </tr>
                                    <tr v-for="(v, k) in dataHistoryMypoint" :key="k">                                        
                                        <td>{{ v.id }}</td>
                                        <td>{{ v.place_reason }}</td>
                                        <td>{{ v.created }}</td>
                                        <td>{{ v.point }}</td>
                                        <td><span :class="'label ' + getCssStatus(v.status)">{{ v.status_label }}</span></td>                                        
                                    </tr>                                                                    
                                </table>
                            </div>                    

                                
                            
                        </div>
                        <div class="tab-content">	
                            <p>Revisa tus puntos acumulados así como los canjeados</p>
                            <div class="table-normal">
                                <table class="table-normal__content">
                                    <tr>                                        
                                        <th>Código</th>
                                        <th>Descripción del canje</th>
                                        <th>Registro</th>
                                        <th>Puntos canjeados</th>
                                        <th>Estado</th>                                        
                                    </tr>
                                    <tr v-for="(v, k) in dataMyExchange" :key="k">
                                        <td>{{ v.id }}</td>
                                        <td>{{ v.asset_exchange.name }}</td>
                                        <td>{{ v.created }}</td>
                                        <td>{{ v.asset_exchange.point_exchange }}</td>
                                        <td><span :class="'label ' + getCssStatus(v.status)">{{ v.status_label }}</span></td>                                        
                                    </tr>                                                                    
                                </table>
                            </div>                    
                            
                        </div>
                    </div>
                </div>                          
            </div>
        </section>        
    </main>       

</template>

<script>

import { ref, computed } from "vue";
import store from "@/store";
import router from "@/router"
import API from "@/api";
import Swal from 'sweetalert2'
import { getCssStatus } from "@/utils/Utils.js";
import { mapState, mapActions } from "vuex";
import UserHeader from "@/views/private/user/components/UserHeader";
import TotalPointExchange from "@/views/private/user/components/TotalPointExchange";

export default {
    name: 'UserAccount',
    components: {
        UserHeader,
        TotalPointExchange,
    },
    setup(){
        
        const dataHistoryMypoint = ref([])
        const dataMyExchange = ref([])
        const dataTotalPointExchange = ref({
            label: "",
            value: ""
        })

        /* dataTotalPointExchange.value = {
            label: "Total de puntos",
            value: 200
        } */        

        const changeTabMyExchange = () => {
            dataTotalPointExchange.value = {
                label: "Mis canjes",
                value: store.state.user.redeemed_point
            }
        }
        const changeTabHistoryPoint = () => {
            console.log('change!')

            setTimeout(function(){ dataTotalPointExchange.value = {
                label: "Total de puntos",
                value: store.state.user.total_point
            } }, 500)
            
        }
        

        const getDataHistoryMyPoint = () => {                
            API.get("/api/point-activity?user=" + store.state.dataToken.id)
            .then(
                response => {
                    dataHistoryMypoint.value = response.data                    
                }
            )
        }

        const getDataMyExchange = () => {                
            API.get("/api/asset-exchange-activity?user=" + store.state.dataToken.id)
            .then(
                response => {
                    dataMyExchange.value = response.data      
                    console.log(dataMyExchange, '<<<<<')              
                }
            )
        }

        
        //const demo = computed(() => mapState(['dataToken']))
        const demo = mapState(['demo2'])
        //console.log(demo, 'MMMMM')

        
        return {           
            router,  
            dataHistoryMypoint, 
            dataMyExchange,
            getDataHistoryMyPoint,
            getDataMyExchange,
            getCssStatus,
            dataTotalPointExchange,
            changeTabMyExchange,
            changeTabHistoryPoint,            
            ...mapActions(['getUser']),
            store,
            demo
            //...mapState(['demo2']),
            //demo: computed(() => mapState(['demo2'])),

        }

    },
    computed: {
        ...mapState(['user'])
    },
    mounted(){
        this.getDataMyExchange();
        this.getDataHistoryMyPoint();
        this.changeTabHistoryPoint();
        this.getUser(store.state.dataToken.id);
        
    },
    created() {
        
    }

    
}
</script>


<style scoped>
    
.page-content {
	max-width: 1000px; 
	margin: 32px auto; 
	padding: 32px; 
	background: #fff;
}
a {
	color: #21D4FD; 
	transition: all 0.3s;
}
a:hover {
	color: #B721FF;
}

.tabbed {
	overflow-x: hidden; /* so we could easily hide the radio inputs */
	margin: 32px 0;
	padding-bottom: 16px;
	border-bottom: 1px solid #ccc;
}

.tabbed [type="radio"] {
	/* hiding the inputs */
	display: none;
}

.tabs {
	display: flex;
	align-items: stretch;
	list-style: none;
	padding: 0;
	border-bottom: 1px solid #ccc;
}
.tab > label {
	display: block;
	margin-bottom: -1px;
	padding: 12px 15px;
	border: 1px solid #ccc;
	background: #eee;
	color: #666;
	font-size: 12px; 
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;	
	transition: all 0.3s;
}
.tab:hover label {
	border-top-color: #333;
	color: #333;
}

.tab-content {
	display: none;
	color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed [type="radio"]:nth-of-type(5):checked ~ .tabs .tab:nth-of-type(5) label {
	border-bottom-color: #fff;
	/* border-top-color: #B721FF; */
	background: #fff;
	color: #222;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
	display: block;
}
</style>